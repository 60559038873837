import { Typography } from "@mui/material";
import React from "react";
import './works.scss';

export default function Wokrs() {
  return(
    <div>
      <Typography component="h3" variant="h3" className="title"> Coming soon</Typography>
    </div>
  )
}